import React from "react"
import { navigate } from "gatsby"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import Button from "@material-ui/core/Button"

// @animations
import Lottie from "react-lottie"
import animationData from "../lotties/notFound"

// @Local & Auth.
import { isLoggedIn } from "../utils/auth"
import SEO from "../components/seo"

const lottieNotFound = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const useStyles = makeStyles(theme => ({
  container: {
    justifyContent: "center",
    marginTop: 50,
    textAlign: "center",
  },
  h1: {
    color: "#000",
    fontWeight: 700,
    textTransform: "uppercase",
    letterSpacing: 5,
    fontSize: 50,
    marginTop: 20,
    marginBottom: 20,
  },
  h2: {
    color: "#000",
    fontWeight: 100,
    textTransform: "uppercase",
    letterSpacing: 3,
    fontSize: 30,
    marginTop: 20,
    marginBottom: 20,
  },
}))

function NotFoundPage() {
  const classes = useStyles()

  const pressed = async () => {
    if (isLoggedIn()) {
      navigate("/app/home")
    } else {
      navigate("/app/login")
    }
  }

  return (
    <Grid container xs={12}>
      <SEO title="404: Página no encontrada" />
      <Grid item xs={12} className={classes.container}>
        <Grid item xs={12}>
          <img
            alt={"Vecol"}
            src={require("../images/logo.png")}
            height={100}
            width={100}
          />
        </Grid>
        <Lottie options={lottieNotFound} height={400} width={400} />
        <Typography className={classes.h2}>Página no encontrada</Typography>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.button}
          onClick={pressed}
          size={"large"}
          startIcon={<ArrowBackIcon />}
        >
          {isLoggedIn() ? "Ir al Inicio" : "Volver"}
        </Button>
      </Grid>
    </Grid>
  )
}

export default NotFoundPage
